import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import ContactForm from '../components/contact-form'
import styled from 'styled-components'

const ContactFormContainer = styled.div`
  max-width: 767px;
  margin: 0 auto;
  padding: 3em 1em;
  label {
    font-weight: bold;
  }
  form {
    margin-top: 10px;
  }
  margin-bottom: 2em;
`
const Heading = styled.h1`
  margin-bottom: 0.35em;
`
const Paragraph = styled.p`
  color: #555;
`

const SecondPage = () => (
  <Layout>
    <SEO title="Conact Us - Free Quote" />
    <ContactFormContainer>
      <Heading>Request a FREE Quote</Heading>
      <Paragraph>
        If you would like to schedule a complimentary in-home design
        consultation and estimate or need more information on a product or
        service that we offer, please fill out the information below.
      </Paragraph>
      <ContactForm showLabels={true} />
    </ContactFormContainer>
  </Layout>
)

export default SecondPage
